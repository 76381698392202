<template>
  <v-row>
    <v-col lg="3" cols="12">
      <v-list
        :style="{
          position: $vuetify.breakpoint.xs || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'relative' : 'fixed'
        }"
      >
        <v-subheader>{{ $t("app.additional_information_faq") }}</v-subheader>
        <v-list-item-group v-model="activeCategory" color="primary">
          <v-list-item v-for="(category, index) in categories" :key="index" @click="scrollToCategory(index)">
            <v-list-item-icon>
              <v-icon>mdi-{{ categoryIcons[category.id] }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="$t('app.faq_categories.' + category.name)" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>

    <v-col lg="8" cols="12">
      <div v-for="(category, i) in categories" :id="'category-' + category.id" :key="i" class="mb-4">
        <p class="font-weight-medium">{{ $t("app.faq_categories." + category.name) }}</p>

        <v-expansion-panels v-model="activeFaqItems[category.id]" multiple>
          <v-expansion-panel
            v-for="faqItem in faqItemsByCategoryId(category.id)"
            :id="'faq-' + faqItem.id"
            :key="faqItem.id"
            class="py-2 mb-3"
            @click="scrollToFaqItem(faqItem.id)"
          >
            <v-expansion-panel-header>{{ faqItem.question }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="replaceTemplate(faqItem.answer)"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import goTo from "vuetify/lib/services/goto";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: this.$t("titles.faq")
    };
  },
  data() {
    return {
      categories: [],
      faqItems: [],
      activeCategory: null,
      activeFaqItems: {}
    };
  },
  computed: {
    ...mapGetters("app", ["appDomain", "redirectorDomain", "cabinetDomain", "filemanagerDomain"]),
    categoryIcons() {
      return {
        2: "file-upload",
        3: "cash-multiple",
        4: "web",
        5: "chart-areaspline",
        6: "cart-check",
        7: "comment-question-outline",
        8: "video-outline"
      };
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.axios.get("/faq").then(response => {
        this.categories = response.data.categories;
        this.faqItems = response.data.items;
        this.activeCategory = 0;

        this.$nextTick(() => {
          this.scrollToItemByQueryHash();
        });
      });
    },

    /**
     * позволяет скролиить к вопросу по hash сегменту урла
     */
    scrollToItemByQueryHash() {
      const hash = this.$route.hash.substr(1);

      if (!hash) {
        return;
      }

      const questionId = parseInt(hash);

      if (!questionId) {
        return;
      }

      this.scrollAndOpenFaqItem(questionId);
    },

    /**
     * возвращает вопросы по категории
     */
    faqItemsByCategoryId(categoryId) {
      return this.faqItems.filter(x => x.category_id === categoryId);
    },

    /**
     * Скроллит у вопросу и открывает его
     */
    scrollAndOpenFaqItem(questionId) {
      const faqItem = this.faqItems.filter(x => x.id === questionId)[0];

      const activeFaqItemIndex = this.findFaqItemIndexByItem(faqItem);

      if (activeFaqItemIndex >= 0) {
        this.activeFaqItems[faqItem.category_id] = [activeFaqItemIndex];
      }

      this.scrollToFaqItem(questionId);
    },

    scrollToFaqItem(questionId) {
      goTo("#faq-" + questionId);

      // select category
      const faqItem = this.faqItems.filter(x => x.id === questionId)[0];

      this.activeCategory = this.findCategoryIndexByCategoryId(faqItem.category_id);
    },

    scrollToCategory(categoryIndex) {
      goTo("#category-" + this.categories[categoryIndex].id);
    },

    findCategoryIndexByCategoryId(categoryId) {
      let categoryIndex = false;

      this.categories.forEach((category, index) => {
        if (category.id === categoryId) {
          categoryIndex = index;

          return;
        }
      });

      return parseInt(categoryIndex);
    },

    findFaqItemIndexByItem(item) {
      const categoryFaqItems = this.faqItems.filter(x => x.category_id === item.category_id);

      for (const index in categoryFaqItems) {
        if (categoryFaqItems[index].id === item.id) {
          return parseInt(index);
        }
      }

      return false;
    },

    replaceTemplate(answer) {
      return answer
        .replaceAll("{fd1_domain}", this.redirectorDomain("fd1"))
        .replaceAll("{fd2_domain}", this.redirectorDomain("fd2"))
        .replaceAll("{ptr_domain}", this.appDomain("ptr"))
        .replaceAll("{ptr_cabinet_domain}", this.cabinetDomain)
        .replaceAll("{ptr_filemanager_domain}", this.filemanagerDomain);
    }
  }
};
</script>
